import axios from "axios";
import EndPointProvider from "../util/EndPointProvider";

export default class UserCallService {
    endpoint = '';
    token = '';
    config;

    constructor(authStore) {
        this.endpoint = EndPointProvider.getEndPoint() + "/usercall/selection/";
        this.token = authStore.token;
        this.config = {
            headers: {Authorization: `Bearer ${this.token}`}
        }
    }

    selectClickUpList = async (selectedListId, callUUID) => {
        const response = await axios.post(this.endpoint + 'clickup', {
            selectedListId : selectedListId,
            zoomCallUUID : callUUID
        }, this.config);
        return response.data;
    }

    selectAirtableBaseAndTable = async (tableId, baseId, callUUID) => {
        const response = await axios.post(this.endpoint + 'airtable', {
            tableId : tableId,
            baseId : baseId,
            zoomCallUUID : callUUID
        }, this.config);
        return response.data;
    }
}